import { Button,  Container, Image } from "react-bootstrap";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../auth-config";
import logo from "../../assets/logo.png"
import chatbot from "../../assets/chatbot.jpg"

export default function Login(props) {
  const { instance } = useMsal();

  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch((e) => {
      console.error(e);
    });
  }

  return (
    <Container bsPrefix="login-container"  >
      <div className="login-page">
       
            <Image className="login-image" src={chatbot} alt="First slide" />
           
        <div className="login-box text-center">
          <Image className="logo" src={logo} alt="logo" width="286px" height="115px"/>
          {props.errorMessage && <div className=".text-label-with-error">{props.errorMessage}</div>
          }
          <div className="login-header">SSG Haptik Chatbot</div>
          <div className="Login-btn-container">
            <Button onClick={() => handleLogin(instance)}
              className={"login-btn"} >
              LOGIN
            </Button>
          </div>
        </div>

      </div>
    </Container>
  );
}
