import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row, Col, Spinner, Image } from "react-bootstrap";
import constants, { DISPATCHTYPE, NOTIFICATIONTYPE, ORDERSTATUS } from "../../utils/constants";
import { addProject, updateProject, addProjectAPI, addProjectHistory } from "../../services/project-service";
import Swal from "sweetalert2";
import xclose from "../../assets/x-close.svg";
import withReactContent from "sweetalert2-react-content";
import Alert from "../common/Alert";
import InputBox from "../common/InputBox";
import DropdownBox from "../common/DropDownBox";
import { formatDateForDisplay } from "../../utils/common-util"
import ResponsiveTable from "../../utils/ResponsiveTable";


const AddEditProjectMaster = (props) => {
  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const [submit, setSubmit] = useState(false);
  const [fieldCount, setFieldCount] = useState([]);
  const [userData, setUserData] = useState(props.userData);
  const [data, setData] = useState(props.finalHistory);
  const [dealerCode, setDealerCode] = useState('');
  const [dealerName, setDealerName] = useState('');
  const [mobile, setMobile] = useState('');
  const [selectedOptionIntAPI, setSelectedOptionIntAPI] = useState('No');
  const [headers, setHeaders] = useState([
    {
      displayName: "Project Number",
      columnName: "project_no",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Planned Dispatch Date",
      columnName: "planned_dispatch_date",
      dataType: "date",
      width: 15,
      displayIndication: true,
      formatter:formatDateForDisplay
    },
    {
      displayName: "Actual Planned Dispatch Date",
      columnName: "actual_dispatch_date",
      dataType: "date",
      width: 15,
      displayIndication: true,
      formatter:formatDateForDisplay
    },
    {
      displayName: "Modified On",
      columnName: "ModifiedOn",
      dataType: "date",
      width: 15,
      displayIndication: true,
      formatter:formatDateForDisplay
    },
    {
      displayName: "Modified By",
      columnName: "ModifiedBy",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Revision Count",
      columnName: "revision_count",
      dataType: "text",
      width: 15,
      displayIndication: true,
    }
  ])

  let action = props.action;
  const [showOptional, setShowOptional] = useState(
    action === "Edit" && props.rowdata.project_no ? false : true
  );
  
  useEffect(() => {
    let errempty=true
    for (let err in error) {          
      if( error[err] !==""){
        errempty=false
        break;
      }

    }
    if(errempty){
        setErrorMessage(); 
    }
   
}, [error])


  useEffect(() => {
    setError();
    setErrorMessage();
  }, [props.action]);

  const submitForm = (e) => {
    e.preventDefault();
    setSubmit(submit ? false : true);
  };

  useEffect(() => {
    const fieldValidation = 2;
    if (fieldCount.length >= fieldValidation) {
      setSubmit(submit ? false : true);
      handleSubmit();
      setFieldCount([]);
    }
  }, [fieldCount, action]);

  const validateDetails = () => {
    let tempError = false;
    for (let errorObj in error) {
      if (error[errorObj]) {
        tempError = true;
        break;
      }
    }

    if (tempError) {
      setErrorMessage("Please check above errors");
    } else {
      setErrorMessage("");
    }
    return tempError;
  };

  const handleSubmit = async () => {
    if (!validateDetails()) {
      let confirmationMessage =
        action === "Add"
          ? "Are you sure do you want to Add New Project?"
          : action === "Edit"
          ? "Are you sure do you want to Edit Project?"
          : "";
      let title =
        action === "Add"
          ? "Add New Project"
          : action === "Edit"
          ? "Edit Project"
          : "";
      MySwal.fire({
        title: title,
        html: confirmationMessage,
        ...constants.CONFIRMATION_BOX_CONFIG,
      }).then(async (selection) => {
        if (selection.isConfirmed) {
          setLoading(true);
          let result;
          if (action === "Add") {
            if (props.projectData.length > 0) {
              if (
                props.projectData.filter(
                  (item) =>
                    item.project_no.toLowerCase() ===
                    projectObj.project_no.toLowerCase()
                ).length > 0
              ) {
                setErrorMessage("Project already exists.");
                setLoading(false);
                return;
              }
            }
          projectObj.revision_count=0
          projectObj.history_no=0
          result = addProject(projectObj);
          result
            .then((resp) => {
              Alert("success", resp.Message);
              props.onHide();
              props.setReload();
            })
            .catch((err) => {
              setErrorMessage(err);
            });
          setLoading(false);
          }
          else {
            if(props.rowdata.actual_dispatch_date.toString() !== projectObj.actual_dispatch_date.toString()) {
            projectObj.revision_count = props.rowdata.revision_count + 1;
            projectObj.history_no = props.rowdata.history_no + 1;
            selectedOptionIntAPI === "Yes" ? projectObj.notification_status=await hitProjectAPI() : projectObj.notification_status = projectObj.notification_status
            addProjectHistory(props.rowdata)
            result = updateProject(projectObj);
            result
              .then((resp) => {
                Alert("success", resp.Message);
                props.onHide();
                props.setReload();
              })
              .catch((err) => {
                setErrorMessage(err);
              });
            setLoading(false);
            }
            else {
            projectObj.revision_count = props.rowdata.revision_count;
            projectObj.history_no = props.rowdata.history_no;
            selectedOptionIntAPI === "Yes" ? projectObj.notification_status=await hitProjectAPI() : projectObj.notification_status = projectObj.notification_status
            console.log(projectObj.notification_status)
            result = updateProject(projectObj);
            result
              .then((resp) => {
                Alert("success", resp.Message);
                props.onHide();
                props.setReload();
              })
              .catch((err) => {
                setErrorMessage(err);
              });
            setLoading(false);
            }
          }
        }
      });
    } else {
      return;
    }
  };

 let blankProjectObj = {
  dealer_code: "",
  driver_number:"",
  notification_type:"",
  actual_dispatch_date:"",
  end_customer_name:"",
  branch:"",
  dispatch_type:"",
  po_number:"",
  completed_status:"",
  notification_status:"",
  product_group:"",
  order_type:"",
  planned_dispatch_date:"",
  order_date:"",
  project_no:"",
  vehicle_consignment_number:"",
  consignment_type:"",
  dealer_name:"",
  order_value:"",
  variable_1:"",
  variable_2:"",
};

  const [projectObj, setProjectObj] = useState(() => {
    if (action === "Edit" || action === "View" || action === "View History") {
      return props.rowdata
    } else {
      return blankProjectObj
    }
  });

  const handleReset = () => {
    if (action === "Add") {
      setProjectObj((prevState) => ({
        ...prevState,
        dealer_code: "",
        driver_number:"",
        notification_type:"",
        actual_dispatch_date:"",
        end_customer_name:"",
        revision_count:"",
        branch:"",
        dispatch_type:"",
        po_number:"",
        completed_status:"",
        notification_status:"",
        product_group:"",
        order_type:"",
        planned_dispatch_date:"",
        order_date:"",
        project_no:"",
        vehicle_consignment_number:"",
        consignment_type:"",
        dealer_name:"",
        order_value:"",
        variable_1:"",
        variable_2:"",
        // active_status: "Active",
      }));
    } else if (action === "Edit") {
      setProjectObj(props.rowdata);
    }
    setShowOptional(action === "Edit" && props.rowdata.project_no ? false : true);
    setError();
    setErrorMessage();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "project_no") {
      setShowOptional(false);
    }
    if (name === "dealer_code") {
      let code = e.target.value;
      setDealerCode(code)
    }
    if (name === "dealer_name") {
      let code = e.target.value;
      setDealerName(code)
    }
    if (name === "order_type" || name === "dealer_name" || name === "dealer_code" || name === "branch" || name === "end_customer_name" || name === "product_group" || name === "project_no") {
    setProjectObj((prevState) => ({
      ...prevState,
      [name]: value.toUpperCase(),
    }));
  } else {
  setProjectObj((prevState) => ({
    ...prevState,
    [name]: value,
  }));
}
  };

  useEffect(() => { 
    if (action === "Add") {
    const user = userData.find(el => el.dealer_code === dealerCode)
    if (user) {
      projectObj.dealer_name = user.organization.toUpperCase();
    } else {
      projectObj.dealer_name = dealerName;
    }
  } else if (action === "Edit") {
    const user = userData.find(el => el.dealer_code === projectObj.dealer_code)
    if (user) {
      setMobile(user.mobile)
    }
  }
  }, [dealerCode])

  let icons = [
  {}
  ];
  

  const hitProjectAPI = async () => {
    try {
      let actualDispDate="";
      let plannedDispDate="";
      if(projectObj.actual_dispatch_date.toString() !== "" && projectObj.actual_dispatch_date.toString() !== undefined){
        const tmpappdd = new Date(projectObj.actual_dispatch_date);
        const formattedDate = tmpappdd.toLocaleDateString('en-GB', {
          day: 'numeric', month: 'short', year: 'numeric'
        }).replace(/ /g, '-');
        actualDispDate=formattedDate;
      }
      if(projectObj.planned_dispatch_date.toString() !== "" && projectObj.planned_dispatch_date.toString() !== undefined){
        const tmddate = new Date(projectObj.planned_dispatch_date);
        const formattedDate = tmddate.toLocaleDateString('en-GB', {
          day: 'numeric', month: 'short', year: 'numeric'
        }).replace(/ /g, '-');
         plannedDispDate=formattedDate;
      }
    let projectAPI = {
      "userId":"",
      "phoneNumber": mobile,
      "countryCode": "+91",   
      "event": "Dispatch date changed",                   
          "traits": {
            "NAME": projectObj.dealer_name,                                
            "Proj No": projectObj.project_no,                        
            "APPDD": actualDispDate,                          
            "Planned Date": plannedDispDate,               
            "Notification Type": projectObj.notification_type,                       
            "Control Variable":  projectObj.variable_1,                   
            "Generic Message": projectObj.variable_2                   
          }              
    }
    const requestBody = JSON.stringify(projectAPI);
    console.log(requestBody, "requestBody")
    let result = await addProjectAPI(requestBody)
    if (result.result === true) {
      return projectObj.notification_status = "Notification Delivered"
    } 
    else {
      return "Notification failed"
    }
  } catch (err){
      return "Notification failed : " + err.response.data.message;
  }
  }

  const commonProps = {
    setError: setError,
    error: error,
    submit: submit,
    setFieldCount: setFieldCount,
    fieldCount: fieldCount,
    onChange: handleChange,
  };
  console.log(props.rowdata);

   return (
    <>
      {loading && (
        <div className="spinner-box">
          {" "}
          <Spinner animation="border" className="spinner"></Spinner>
        </div>
      )}
      <Modal {...props} size="lg" backdrop="static" className="ProjectModal">
        <Modal.Header>
          <Image src={xclose} onClick={props.onHide}></Image>
          <Modal.Title className="modal-title">{action} Project</Modal.Title>
        </Modal.Header>
        {action !== "View History" &&
        <Modal.Body>
          {action === "Add" && <Button onClick={props.handleUpload} className="mb-4">Upload Excel</Button>}
          <Row>
          <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Type Of Orders"
                maxLength={200}
                name="order_type"
                value={projectObj.order_type}
                required={true}
                isPattern={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Dealer Code"
                maxLength={200}
                name="dealer_code"
                value={projectObj.dealer_code}
                required={true}
                isPattern={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Dealer Name"
                maxLength={200}
                name="dealer_name"
                value={projectObj.dealer_name}
                required={true}
                isPattern={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="End Customer Name"
                maxLength={200}
                name="end_customer_name"
                value={projectObj.end_customer_name}
                required={true}
                isPattern={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="date"
                label="Order Booked Date"
                maxLength={200}
                name="order_date"
                value={projectObj.order_date}
                required={true}
                class={action === "Add" ? "add-date" : "edit-date"}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Project Number"
                maxLength={200}
                name="project_no"
                value={projectObj.project_no}
                required={true}
                isPattern={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Product Group"
                maxLength={200}
                name="product_group"
                value={projectObj.product_group}
                required={true}
                isPattern={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="number"
                label="Order Value In LACS"
                maxLength={200}
                name="order_value"
                value={projectObj.order_value}
                required={true}
                isDecimalDigit={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Branch"
                maxLength={200}
                name="branch"
                value={projectObj.branch}
                required={true}
                isPattern={true}
                readOnly={
                  action === "Edit" || action === "View" ? true : undefined
                }
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="date"
                label="Planned Dispatch Date"
                maxLength={200}
                name="planned_dispatch_date"
                value={projectObj.planned_dispatch_date}
                class={action === "Add" ? "add-date" : "edit-date"}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="date"
                label="Actual Planned Dispatch Date"
                maxLength={200}
                name="actual_dispatch_date"
                value={projectObj.actual_dispatch_date}
                class={action === "Add" ? "add-date" : "edit-date"}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              {(action === "Add" || "Edit") && (
                <>
                  <DropdownBox
                    label="Partial / Full / Final"
                    name="dispatch_type"
                    value={projectObj.dispatch_type}
                    required={true}
                    {...commonProps}
                  >
                    <option value={""}>Select Dispatch Type</option>
                    {DISPATCHTYPE
                      ? DISPATCHTYPE.map((Type) => (
                          <option key={Type} value={Type}>
                            {Type}
                          </option>
                        ))
                      : null}
                  </DropdownBox>
                </>
              )}
             
              {action === "View" && (
                <>
                  <InputBox
                    type="text"
                    label="Partial / Full / Final"
                    name="dispatch_type"
                    value={projectObj.dispatch_type}
                    readOnly={action === "Edit" || "View" ? true : undefined}
                    {...commonProps}
                  />
                </>
              )}
            </Col>
            <Col lg={4} md={12} className="txt-input">
              {action === "Add" && (
                <>
                  <DropdownBox
                    label="Order Status"
                    name="completed_status"
                    value={projectObj.completed_status}
                    readOnly={action === "Edit" ? true : undefined}
                    {...commonProps}
                  >
                    <option value={""}>Select Order Status</option>
                    {ORDERSTATUS
                      ? ORDERSTATUS.map((Type) => (
                          <option key={Type} value={Type}>
                            {Type}
                          </option>
                        ))
                      : null}
                  </DropdownBox>
                </>
              )}
              {action === "Edit" && (
                <>
                  <DropdownBox
                    label="Order Status"
                    name="completed_status"
                    value={projectObj.completed_status}
                    readOnly={action === "Edit" ? true : undefined}
                    {...commonProps}
                  >
                    <option value={""}>Select Order Status</option>
                    {ORDERSTATUS
                      ? ORDERSTATUS.map((Type) => (
                          <option key={Type} value={Type}>
                            {Type}
                          </option>
                        ))
                      : null}
                  </DropdownBox>{" "}
                </>
              )}
              {action === "View" && (
                <>
                  <InputBox
                    type="text"
                    label="Order Status"
                    name="completed_status"
                    value={projectObj.completed_status}
                    readOnly={action === "Edit" || "View" ? true : undefined}
                    {...commonProps}
                  />
                </>
              )}
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="PO Number"
                maxLength={200}
                name="po_number"
                value={projectObj.po_number}
                isPattern={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Vehicle / Consignment Number"
                maxLength={200}
                name="vehicle_consignment_number"
                value={projectObj.vehicle_consignment_number}
                isPattern={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Type of Consignment"
                maxLength={200}
                name="consignment_type"
                value={projectObj.consignment_type}
                isPattern={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Driver Number"
                maxLength={200}
                name="driver_number"
                value={projectObj.driver_number}
                isPattern={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Notification Status"
                maxLength={200}
                name="notification_status"
                value={projectObj.notification_status}
                readOnly={true}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              {action === "Add" && (
                <>
                  <DropdownBox
                    label="Notification Type"
                    name="notification_type"
                    value={projectObj.notification_type}
                    readOnly={action === "Edit" ? true : undefined}
                    {...commonProps}
                  >
                    <option value={""}>Select Notification Type</option>
                    {NOTIFICATIONTYPE
                      ? NOTIFICATIONTYPE.map((Type) => (
                          <option key={Type} value={Type}>
                            {Type}
                          </option>
                        ))
                      : null}
                  </DropdownBox>
                </>
              )}
              {action === "Edit" && (
                <>
                  <DropdownBox
                    label="Notification Type"
                    name="notification_type"
                    value={projectObj.notification_type}
                    readOnly={action === "Edit" ? true : undefined}
                    {...commonProps}
                  >
                    <option value={""}>Select Notification Type</option>
                    {NOTIFICATIONTYPE
                      ? NOTIFICATIONTYPE.map((Type) => (
                          <option key={Type} value={Type}>
                            {Type}
                          </option>
                        ))
                      : null}
                  </DropdownBox>{" "}
                </>
              )}
              {action === "View" && (
                <>
                  <InputBox
                    type="text"
                    label="Notification Type"
                    name="notification_type"
                    value={projectObj.notification_type}
                    readOnly={action === "Edit" || "View" ? true : undefined}
                    {...commonProps}
                  />
                </>
              )}
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Generic Message"
                maxLength={200}
                name="variable_1"
                value={projectObj.variable_1}
                isPattern={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            <Col lg={4} md={12} className="txt-input">
              <InputBox
                type="text"
                label="Control Variable"
                maxLength={200}
                name="variable_2"
                value={projectObj.variable_2}
                isPattern={true}
                readOnly={action === "View" ? true : undefined}
                {...commonProps}
              />
            </Col>
            {action === "Edit" &&
            <Col lg={4} md={12} className="txt-input">
              SEND NOTIFICAITON : 
              {" "}
            <label>
              <input
                type="radio"
                name="hitAPI"
                value="Yes"
                checked={selectedOptionIntAPI === 'Yes'}
                onChange={()=>{setSelectedOptionIntAPI("Yes")}}
              />
              Yes
            </label>
            {" "}
            <label>
              <input
                type="radio"
                name="hitAPI"
                value="Yes"
                checked={selectedOptionIntAPI === 'No'}
                onChange={()=>{setSelectedOptionIntAPI("No")}}
              />
              No
            </label>
            </Col>
            }
          </Row>
          <Row>
            <Col>
              {errorMessage && (
                <Form.Text className="text-danger">{errorMessage}</Form.Text>
              )}
              <hr></hr>
            </Col>
          </Row>
          {action !== "View" && (
            <Row>
              <div className="actionbtn">
                <Button
                  className="btn btn-outline-primary reset-button"
                  onClick={handleReset}
                  variant="outline-primary"
                >
                  Reset
                </Button>
                <Button
                  className="btn btn-primary semibold-togglebutton right-alignement btn-color"
                  type="submit"
                  onClick={submitForm}
                >
                  {action === "Edit" ? "Update" : "Save"}
                </Button>
              </div>
            </Row>
          )}
        </Modal.Body>
        }
        {action === "View History" && 
        <Modal.Body>
        <Row>
         <ResponsiveTable
          headers={headers}
          data={data}
          icons={icons}
          dataType={"Maintain Project History"}
          showDownload={true}
          excelDownload={true}
          downloadFileName={"projectMasterUploadStatus.xlsx"}
        ></ResponsiveTable>
        </Row>
      </Modal.Body>
      }
      </Modal>
    </>
  );
};

export default AddEditProjectMaster;
