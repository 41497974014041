import React, { useEffect, useState } from "react";
import Switch from '../../utils/Switch';
import { Button, Form, Modal, Row, Col, Spinner, Image } from "react-bootstrap";
import constants from "../../utils/constants";
import DropFileInput from "../../utils/DropFileInput";
import Swal from "sweetalert2";
import xclose from "../../assets/x-close.svg"
import withReactContent from "sweetalert2-react-content";
import Alert from "../common/Alert";
import InputBox from "../common/InputBox";
import DropdownBox from "../common/DropDownBox";
import { addProductImage, submitFiles } from "../../services/productimage-service";
import "../common/InputBox.css"
import "../common/DropDownBox.css"
import Validate from "../../utils/FileValidation";
const AddEditProductImageMaster = (props) => {

    const MySwal = withReactContent(Swal);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});
    const [errorMessage, setErrorMessage] = useState();
    const [submit, setSubmit] = useState(false);
    const [fieldCount, setFieldCount] = useState([]);
    const [files, setFiles] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [creflag, setCreflag] = useState(false);
   
    let action = props.action;
    const [showOptional, setShowOptional] = useState(
        action === "Edit" && props.rowdata.product_name ? false : true
    );
  
    
    useEffect(() => {
        const { isValid, error } = Validate(files, []);
        setError(error);
        setIsValid(isValid);
    }, [files]);

    useEffect(() => {
        let errempty=true
        for (let err in error) {          
          if( error[err] !==""){
            errempty=false
            break;
          }

        }
        if(errempty){
            setErrorMessage(); 
        }
       
    }, [error])

    let defaultObj=  {
        product_name: "",
        product_image: "",       
    }
    const [productImageObj, setProductImageObj] = useState(
        action === "Edit" || action == "View"
            ? props.rowdata
            :
            defaultObj
    );


    useEffect(() => {
        setError();
        setErrorMessage();
    }, [props.action])
  

    const submitForm = (e) => {
        e.preventDefault();
        setSubmit(submit ? false : true);
    }

    

    useEffect(() => {
        const fieldValidation = 1
        if (fieldCount.length >= fieldValidation) {
            setSubmit(submit ? false : true);
            handleSubmit();
            setFieldCount([]);
        }
    }, [fieldCount, action])

    const validateDetails = () => {

        let tempError = false;
        for (let errorObj in error) {
            if (error[errorObj]) {
                tempError = true;
                break;
            }
        }
       
        if (tempError) {
            setErrorMessage("Please check above errors")
        } else {
            setErrorMessage("")
        }
        return tempError;
    }

    const handleSubmit = async () => {
        if (!validateDetails()) {
            let confirmationMessage = action === "Add" ? "Are you sure do you want to Add New Product Image?" : action === "Edit" ? "Are you sure do you want to Edit Product Image?" : "";
            let title = action === "Add" ? "Add New Product Image" : action === "Edit" ? "Edit Product Image" : "";
            MySwal.fire({
                title: title,
                html: confirmationMessage,
                ...constants.CONFIRMATION_BOX_CONFIG
            }).then(async (selection) => {
                if (selection.isConfirmed) {

                    setLoading(true)
                    let result;
                        if (props.productImageData.length > 0) {
                           
                            if (props.productImageData .filter((item) =>((action === "Add") || (action==="Edit" && (item.SK.toLowerCase().trim() !== productImageObj.SK.toLowerCase().trim())) )&&(item.product_name.toLowerCase().trim() === productImageObj.product_name.toLowerCase().trim())).length > 0) {
                                 setErrorMessage("Product Image already exists.");
                                            setLoading(false);
                                            return;
                                }

                            }
                    productImageObj.masterName = "Profuct Image";
                    productImageObj.product_name=productImageObj.product_name.trim();
                    productImageObj.product_image=productImageObj.product_image.trim();
                    delete productImageObj.CreatedOnForDispaly;                   
                    result = addProductImage(productImageObj);
                    result.then((resp) => {
                       
                        Alert("success", resp.Message);
                        props.onHide();
                        props.setReload();
                    }).catch((err) => {
                        setErrorMessage(err);
                    })
                    setLoading(false);
                }
            })
        }
        else {

            return;
        }
    };


    const handleReset = () => {
        if (action === "Add") {
            setProductImageObj(defaultObj);
        } else if (action === "Edit") {
            setProductImageObj(props.rowdata)
        }
        setShowOptional(action === "Edit" && props.rowdata.product_name ? false : true);
        setError();
        setErrorMessage()
    }




    const handleChange = (e) => {
        const { name, value } = e.target;

if(name=="product_name"){
    setProductImageObj((prevState) => ({
        ...prevState,
        [name]: value?.toUpperCase()
    }));

}else{
    setProductImageObj((prevState) => ({
        ...prevState,
        [name]: value
    }));

}

      
    }

    const commonProps = { setError: setError, error: error, submit: submit, setFieldCount: setFieldCount, fieldCount: fieldCount, onChange: handleChange }


    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner">
            </Spinner></div>}
            <Modal
                {...props}
                size="lg"
                backdrop="static"
                className="ProductImageMaster"
            >
                <Modal.Header >
                    <Image src={xclose} onClick={props.onHide}></Image>
                    <Modal.Title className="modal-title">
                        {action} Product Image
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row >
                        <Col lg={5} md={12} className="txt-input">
                            <InputBox type="text" label="Product Name" maxLength={50} name="product_name"  isPattern={true} value={productImageObj.product_name} required={true}   readOnly={(action === "Edit" || action === "View")  ? true : undefined} {...commonProps} />
                        </Col>
                        <Col lg={8} md={12}>
                        <InputBox type="text" label="Product Image" maxLength={300} name="product_image" value={productImageObj.product_image} required={true} readOnly={(action === "View")  ? true : undefined}  {...commonProps} />
                        </Col>    
                                           
                    </Row>
                    <Row>
                        <Col>
                            {errorMessage && (
                                <Form.Text className="text-danger">
                                    {errorMessage}
                                </Form.Text>
                            )}
                            <hr></hr>

                        </Col>
                    </Row>

               {action !== "View" &&    <Row>
                        <div className="actionbtn">
                            <Button className="btn btn-outline-primary reset-button" onClick={handleReset} variant="outline-primary">Reset</Button>
                            <Button className="btn btn-primary semibold-togglebutton right-alignement btn-color" type="submit" onClick={submitForm}>
                                {action === "Edit" ? "Update" : "Save"}
                            </Button>
                        </div>
                    </Row>
               }

                </Modal.Body>
            </Modal >

        </>
    );
}

export default AddEditProductImageMaster;
