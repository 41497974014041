import './responsive-table.css';
import './responsive-table-layout.css';

import { Table, OverlayTrigger, Tooltip, Card, Form, Row, Col, Container, Image, Button } from "react-bootstrap";
import Search from './Search';
import React, { useEffect, useMemo, useState } from 'react';
import Sort from './Sort';
import Pagination from './Pagination';
import Filter from './Filter';
import GlobalSearch from './GlobalSearch';
import ExcelDownload from './ExcelDownload';
import constants from './constants';
import Switch from './Switch';
import plus from "../assets/plus.svg"
import ColumnGrid from './ColumnGrid'
import xFilter from "../assets/x-filter.svg"
import { searchAll } from './common-filter';
import { Link } from "react-router-dom";



export default function ResponsiveTable(props) {
    const [data, setData] = useState(props.data);
    const [pageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchList, setSearchList] = useState({});
    const [showDownButn, setShowDownButn] = useState(true);
    const [writeAccess, setWriteAccess] = useState(false);
    const [sortList, setSortList] = useState({});
    const [gridHeader, setGridHeader] = useState(props.headers);
    const [downloadData, setDownloadData] = useState([]);
    const[checkboxVal,setCheckBoxVal]=useState(false);  
   
    useEffect(() => {
        setData(props.data)
    }, [props.data])

    useEffect(() => {
        setGridHeader(props.headers)
    }, [props.headers])

    useEffect(() => {
        let AuthorizedSessionstmp = sessionStorage.getItem("AuthorizedSessions");
        let AuthorizedSessions = AuthorizedSessionstmp !== "" ? JSON.parse(AuthorizedSessionstmp) : {};
        for (let parentMenu in AuthorizedSessions) {
            for (let menu of AuthorizedSessions[parentMenu]) {
                if (menu.SubMenu == props.sessionName && menu.Write) {
                    setWriteAccess(true)
                    break;
                }
            }
        }
    }, [props.sessionName])
    const filteredResult = useMemo(() => {
        let firstPageIndex = 0;
        if (currentPage > 1) {
            firstPageIndex = (currentPage - 1) * pageSize;
        }
        if (firstPageIndex >= data?.length) {
            firstPageIndex = 0;
            const totalPageCount = Math.ceil(data?.length / pageSize);
            if (totalPageCount > 0) {
                setCurrentPage(totalPageCount)
            } else {
                setCurrentPage(1)
            }
        }

        let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
        return data && data.length ? data.slice(firstPageIndex, lastPageIndex) : [];
    }, [currentPage, data, pageSize]);

    const handleChangeCheckbox = (e, item) => {
      
        setCheckBoxVal(!checkboxVal);
       
        if(e.target.checked) {
          downloadData.push(item);         
          } else {
            const Index = downloadData.findIndex((element) => element.PK === item.PK && element.SK === item.SK );
            if (Index !== -1) {
              downloadData.splice(Index, 1);
            }
          }
         
    }
    const handleSelectAll = (e) => {      
        if (e.target.checked) {
            setDownloadData(data);        
        } else {
            setDownloadData([]);
        }
       
      };

    return (<Container bsPrefix='custom-grid'>
        <Row className="global-search-bar">
            <Col bsPrefix='search-col'>
                {/* <Button className={`add-btn ${!showDownButn ? "hide" : ""} ${writeAccess == false ? "disabled" : ""}`} onClick={props.handleAdd}><span className="add-btn-label">Add {props.button}<Image className="add-btn-img" src={plus}></Image></span></Button> */}
                {props.hasOwnProperty("button") && <Button className={`add-btn ${!showDownButn ? "hide" : ""}`} onClick={writeAccess && props.handleAdd}><span className="add-btn-label">Add {props.button}<Image className="add-btn-img" src={plus}></Image></span></Button>}
                <div className='search-bar-right'>  <div className='global-search'> <GlobalSearch className="global-search-col " setData={setData} headers={props.headers} data={props.data} setShowDownButn={setShowDownButn} showDownButn={showDownButn}></GlobalSearch></div>
                 {props.DisplayColumnChooser && <ColumnGrid className="column-selector" headers={gridHeader} setGridHeader={setGridHeader} />}
                {/* {showDownButn && <ExcelDownload className="download-btn-col" headers={props.headers} data={downloadData} />} */}
                    {(downloadData.length > 0) && props.showDownload && <ExcelDownload className="download-btn-col" headers={props.headers} data={downloadData} fileName={props.downloadFileName} />}
                </div>
            </Col>
        </Row>
        <Row><Col className='filter-selected-col gap-2'>{searchList && Object.keys(searchList).map((key, index) => {
            let filterValueSelected = searchList[key][0] && searchList[key][0] != "" ? searchList[key].displayName + ": " + searchList[key][0] : "";
            filterValueSelected = searchList[key][1] && searchList[key][1] != "" && searchList[key].condition != "" ? filterValueSelected + " " + searchList[key].condition + " " + searchList[key][1] : filterValueSelected;
            if(searchList[key].filterType == "Range"){
                filterValueSelected = searchList[key][1] && searchList[key][1] != "" ? filterValueSelected + " To " + searchList[key][1] : filterValueSelected;
            }
            return (<React.Fragment key={index}> {filterValueSelected && <div className='filter-selected'>
                <span className='filter-selected-label'>{filterValueSelected} </span>
                <Image className='filter-selected-cross' src={xFilter} onClick={() => {
                    searchList[key][0]="";
                    searchList[key][1]="";
                    let propsTemp={setData:setData,data:props.data};
                    searchAll(propsTemp,searchList)
                }}></Image></div>}</React.Fragment>)
        })}</Col></Row>
        <Container bsPrefix='custom-grid-table'>
        <Table className="responsiveTable">
            <thead>
                <tr>
                    <th className='fixed'></th>
                    <th className='fixed'>
                       { props.excelDownload &&
                          <Form.Check className='table-checkbox' name="select"
                         onChange={handleSelectAll}
                         ></Form.Check> 
                        }
                    </th>
                    {props.icons && <th className="fixed icon" style={{ right: 0, minWidth: props?.icons?.length * 17 + 40 + "px" }}>
                                {props?.icons?.map((icon, index) =>
                                    <div key={index}>&nbsp;</div>
                                )
                                }
                            </th>}
                    {gridHeader.map((header, index) =>
                        // for hide show column
                         {
                          return  ( header.displayIndication &&  
                       <th key={index} className={`${constants.FIXED_COLUMN.includes(header.columnName)?"fixed":""}`}> <div className='table-th'>{header.displayName}
                            {header.dataType !== "link" ? <><Sort data={data} setData={setData} sortList={sortList} setSortList={setSortList} field={header.columnName}></Sort>
                            <Filter
                                index={index}
                                data={props.data}
                                columnName={header.columnName}
                                displayName={header.displayName}
                                setData={setData}
                                searchList={searchList}
                                dataType={header.dataType}
                            /></> : <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>}
                        </div>
                        </th>
                    )
                     }
                    )}
                   
                </tr>
                {/* <tr><td></td>
                    {props.headers.map((header, index) =>
                        <td key={index}><Search masterData={props.data} index={index}
                            length={props?.headers?.length}
                            setData={setData} data={data} columnName={header.columnName}
                            searchList={searchList} dataType={header.dataType} displayName={header.displayName}
                        ></Search></td>
                    )}
                    {props.icons?.length > 0 && <th></th>}
                </tr> */}
            </thead>
            <tbody>
                {filteredResult.map((item, itemIndex) =>
                    <tr key={itemIndex}>
                        <td className={`status ${item.Status} fixed`}>
                           
                        </td>
                        <td className="width-3 fixed">
                        { props.excelDownload && <Form.Check name="select"
                            checked={ downloadData.length > 0 ? (downloadData.filter(e => (e.PK === item.PK && e.SK === item.SK)).length <= 0) ? false : true : false
                            }
                            className='table-checkbox'
                            // checked={downloadData.includes(item.PK) &&downloadData.includes(item.SK)}
                             onChange={(e) => {
                                handleChangeCheckbox(e,item);
                            }} ></Form.Check> 
                        }
                        </td>
                        <td className='icon fixed right'>
                            {props.icons.map((icon, index) =>
                               <>  <Image className={`table-icon`} key={index} src={icon.srcv} pointer="Cursor" onClick={() => { {icon.clickv(item)} }}></Image>
                            <Image className={`table-icon ${writeAccess == false ? "disabled" : ""}`} key={index} src={icon.src} pointer="Cursor" onClick={() => {if(writeAccess)  {icon.click(item)} }}></Image>                               
                            <Image className={`table-icon ${writeAccess == false ? "disabled" : ""}`} key={index} src={icon.srcd} pointer="Cursor" onClick={() => { if(writeAccess) {icon.clicks(item)} }}></Image>
                            <Image className={`table-icon`} key={index} src={icon.srcvh} pointer="Cursor" onClick={() => { {icon.clickvh(item)} }}></Image>
                                </>
                           )}
                        </td> 
                        {gridHeader.map((header, index) =>
                        // for hide show column
                         {
                            let displayValue = header.formatter ? header.formatter(item[header.columnName], header.displayName) : item[header.columnName];
                               
                          return  ( header.displayIndication &&  

                            <td key={index} className={`width-${header.width} ${constants.FIXED_COLUMN.includes(header.columnName)?"fixed":""}`}>
                                {/* <OverlayTrigger
                                    placement="right"
                                    overlay={<Tooltip>{item[header.columnName]}</Tooltip>}
                                > */}
                                    <div className={`responsive-td ${item[header.columnName]}`}>
                                        {/* {header.columnName == "Status" &&
                                            <Switch
                                                isOn={item.Status == constants.ACTIVE ? true : false}
                                                index={itemIndex}
                                                handleToggle={() => {
                                                    if (writeAccess) {
                                                        item.Status = item.Status == constants.ACTIVE ? constants.INACTIVE : constants.ACTIVE;
                                                        header.setStatus(item)
                                                    }
                                                }}
                                            ></Switch>} */}
                                             {(header.dataType == "link" && (item[header.columnName] !== "" && item[header.columnName] !== undefined)) &&

<Link to={displayValue} target="_blank">
{displayValue}
</Link>
//<Link   pointer="Cursor" onClick={() => { if(writeAccess) {icon.click(item)} }}></Link>

}
{header.dataType !== "link" && displayValue}</div>
                               
                                {/* </OverlayTrigger> */}
                            </td>
                          )
                         }
                        )}
                            
                          
                    </tr>
                )}
            </tbody>
        </Table>
        </Container>
        <div className="responsive-div">
            {filteredResult.map((item, itemIndex) =>
                <Card key={itemIndex} className={`responsive-card mb-3 ${item.Status}`}>
                    <Card.Header className="responsive-card-header"> {props.icons.map((icon, index) => <>
                     <Form.Check name="select"
                     checked={ downloadData.length > 0 ? (downloadData.filter(e => (e.PK === item.PK && e.SK === item.SK)).length <= 0) ? false : true : false
                     }
                     className='custom-checkbox'
                      onChange={(e) => {
                         handleChangeCheckbox(e,item);
                     }} ></Form.Check>
                        <Image className={`${writeAccess == false ? "disabled" : ""}`} key={index} src={icon.src} onClick={() => { if(writeAccess) {icon.click(item)} }}></Image>
                        </>)}</Card.Header>
                      {gridHeader.map((header, index) =>
                        // for hide show column
                         {
                          return  ( header.displayIndication &&  
                        <Card.Body key={index} className={`responsive-row`}>
                            <span className="responsive-header">{header.displayName}</span>
                            <span className={`responsive-value ${item[header.columnName]}`}>
                                {header.columnName == "Status" &&
                                    <Switch
                                        isOn={item.Status == constants.ACTIVE ? true : false}
                                        index={itemIndex}
                                        handleToggle={() => {
                                            if (writeAccess) {
                                                item.Status = item.Status == constants.ACTIVE ? constants.INACTIVE : constants.ACTIVE;
                                                header.setStatus(item)
                                            }
                                        }}
                                    ></Switch>}
                                {item[header.columnName]}</span>
                        </Card.Body>
                          )
                                    }
                    )}
                </Card>
            )}
        </div>
        <Pagination
            currentPage={currentPage}
            totalCount={data ? data.length : 0}
            data={data}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            dataType={props.dataType}
        />
    </Container >
    )
}