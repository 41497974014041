import { Nav, Collapse, Image } from "react-bootstrap";
import { List } from "react-bootstrap-icons";
import NavItem from "../../utils/NavItem";
import React, { useEffect, useState } from "react";
import godrejLogo from "../../assets/godrej.svg";
import dashboard from "../../assets/dashboard.svg"
import transaction from "../../assets/transaction.svg"
import adminIcon from "../../assets/admin.svg"
import maintenance from "../../assets/maintenance.svg"

export default function SideBar(props) {

  const [open, setOpen] = useState({});
  let icons = { "Transaction": transaction, "Admin": adminIcon, "Master": maintenance }
  let AuthorizedSessionstmp = sessionStorage.getItem("AuthorizedSessions");
  let AuthorizedSessions = AuthorizedSessionstmp !== "" ? JSON.parse(AuthorizedSessionstmp) : {};


  useEffect(() => {

    if (props.sidebar == "closed") {
      setOpen({})
    }
  }, [props.sidebar])



  return (
    <>
      <div className="sidebar-head">
        <List className="head-toggle" onClick={() => { props.setSidebar(props.sidebar === "closed" ? "open" : "closed") }}></List>
      </div>
      <div className={`sidebar-body ${props.sidebar}`}>
        <Nav bsPrefix="nav-list" defaultActiveKey={"Dashboard"}>
        <NavItem menuType={"parent"} menu={"Dashboard"} path={"/"} icon={dashboard}></NavItem>
          {Object.keys(AuthorizedSessions).map((parentMenu, index) => {
            return <React.Fragment key={index}>
              {AuthorizedSessions[parentMenu].length > 0 ? <React.Fragment>
                <NavItem menuType={"parent"} menu={parentMenu} setOpen={setOpen} open={open} ariaControls={parentMenu} icon={icons[parentMenu]}></NavItem>
                <Collapse in={open[parentMenu]}>
                  <div id={parentMenu}>
                    {AuthorizedSessions[parentMenu].map((item, index) => {
                      return<NavItem key={index} menuType={"menu"} menu={item.SubMenu} path={item.Path} setSidebar={props.setSidebar} ></NavItem>
                    })}
                  </div></Collapse>
              </React.Fragment>
                : <></>
              }
            </React.Fragment>
          })}

        </Nav>
        <div className="footer">
          <Image className="footer-logo mt-auto" src={godrejLogo} />
        </div>
      </div>
    </>
  );
}
