import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {  Image } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { downloadAttachment } from "../services/dropfile-service";
import SucessRight from "../assets/SucessRight.svg";
import Validate  from "./FileValidation"
import DltIcon from "../assets/DltIcon.svg";
import "../utils/dropFileInput.css"
import downloadIcon from "../assets/downloadIcon.svg";
import { Link } from 'react-router-dom'

import 'react-dropzone-uploader/dist/styles.css';

const DropFileInput = (props) => {
  const MySwal = withReactContent(Swal);
  const wrapperRef = useRef(null);
  const inputRef = useRef(null);
  const prevFile = props?.previousFiles;
  const [fileError, setFileError] = useState(false);

  const [fileList, setFileList] = useState(
    props?.previousFiles ? props?.previousFiles : []
  );
  const [prevFileNum] = useState(props?.previousFiles?.length);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    const { isValid, error } = Validate(newFile, []);
    if(isValid){
    if (newFile) {
      setFileError(false);
      props.setError((prev) => ({ ...prev, ["ProductImage"]: "" }));
      const updatedList = [...fileList, newFile];
      console.log("updatedList",updatedList)
      setFileList(updatedList);
      props.onFileChange(updatedList);
      props.setFileData(newFile)
    }
   
  }
  else{
   setFileError(true);
    props.setError((prev) => ({ ...prev, ["ProductImage"]: error }));
   
  }
  
 
  };

  const fileRemove = (file) => {
    props.setError((prev) => ({ ...prev, ["ProductImage"]: "" }));
    setFileError(false);
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
    // }
  };

  const downloadFile = (fileName) => {
    downloadAttachment(fileName).catch((err) => {
      console.error("Error in Fetching file.", err);
      MySwal.fire("Error!", "Error in Fetching file.", "error");
    });
  };

  const handleUploadClick = () => {
    inputRef.current?.click();
  };
  return (
    <div className="upload-box">
      {/* <h6>ProductImage</h6> */}

      {!props.readOnly && (
        <div>
          <span className="attach-note-color2">
          Only 1 attachment is allowed to upload and format should be .xlsx, .xlx formats file.
          </span>
          
          <div
            ref={wrapperRef}            
            className="drop-file-input"
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
          >
             {fileList?.length <= 0 && (
            
            <div 
            className={fileError ? "text-label-with-error" + " drop-file-input__label"  : "drop-file-input__label"}>            
              <Image src={downloadIcon}
                       alt="Upload"
                       className='upload-btn'                        
                    />
              <span>  Drag & Drop your files here or </span>
              {/* <span className="browse-files-text"> <Link>Browse Files</Link> </span> */}
              <span className="browse-files-text"> <Link onClick={handleUploadClick} >{fileList?.length>0 ? `${fileList[0].name}` : 'Browse Files'}</Link> </span>
              
              <input
                type="file"
                value=""
                title="Select or Drag Files here"
                onChange={onFileDrop}
                ref={inputRef}
                style={{ display: 'none' }}
              >
              </input>
            </div>
             )}
              {fileList?.length > 0 && (
        <div className="drop-file-input__label">
          {fileList.map((item, index) => {
            let fileName = item.name;

            return (
              <div className="files-list-map" key={index}>
                 <Link
                  className="files-note cursor-pointer wrap-word"
                  onClick={(e) => {
                    downloadFile(fileName);
                  }}
                >
                  {fileName}
                </Link>
                
                <img src={DltIcon} onClick={() => fileRemove(item)} className='attachment-remove' />
                <img src={SucessRight}  className='success-right' />
                <span className='success-right' >100%</span> &nbsp;
                  
              </div>
            );
          })}
        </div>
      )}

          </div>
        </div>
      )}
     
    </div>
  );
};

DropFileInput.propTypes = {
  onFileChange: PropTypes.func,
};

export default DropFileInput;
