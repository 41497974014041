import React, { useEffect, useState } from "react";
import "./App.css";
import "./helpers/interceptor";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./auth-config";
import Home from "./components/base/Home";
import Login from "./components/base/Login";
import { authenticate } from "./services/user-service";

function App() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [validUser, setValidUser] = useState();
  const [idToken, setIdToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (isAuthenticated) {
      RequestProfileData();
    }
    if (idToken && idToken !== "") {
      sessionStorage.setItem("Token", idToken);
      getUserData();
    }
  }, [isAuthenticated, idToken]); // eslint-disable-line react-hooks/exhaustive-deps
  function RequestProfileData() {
    const request = {
      ...loginRequest,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setIdToken(response.idToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setIdToken(response.idToken);
        });
      });
  }
  async function getUserData() {
    let email = accounts[0].username;
    let result = await authenticate(email).catch((err) => {
      console.log("Error in fetching details", err)
      setValidUser(false);
      setErrorMessage(err?.response?.data?.Message)
      sessionStorage.clear()
    }
    );
     if (result?.data && result?.data?.UserData && result?.data?.UserData !== "") {

      let userData = result.data.UserData;
      console.log("userData ", userData);

      sessionStorage.setItem("UserName", userData.EmpName ? userData.EmpName : "");
      sessionStorage.setItem("Role", userData.RoleCode ? userData.RoleCode : "");
      sessionStorage.setItem("Token", result.data.Token);
      sessionStorage.setItem("Email", userData.EmpEmail ?  userData.EmpEmail : "");
      sessionStorage.setItem("AuthorizedSessions", userData.AuthorizedSessions ? JSON.stringify(userData.AuthorizedSessions) : []);
      setValidUser(true);
    }
  }

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        {validUser === true && <Home />}
        {validUser === false && (
          <Login errorMessage={errorMessage} />
        )}
      </AuthenticatedTemplate>
     
      <UnauthenticatedTemplate>
        <Login />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );

  // async function getUserData() {
  //   sessionStorage.setItem("UserName", "Snehal Kurhade");

  //   sessionStorage.setItem("Role", "Admin");

  //   sessionStorage.setItem("Token", "");

  //   sessionStorage.setItem("Email", "snehalsk@godrej.com");

  //   sessionStorage.setItem("AuthorizedSessions", JSON.stringify({"Admin": [ {"Path": "/role", "Read": true,"SubMenu": "Role and Authorization"},
  //     { "Path": "/user","Read": true, "SubMenu": "User"}]}));

  //   setValidUser(true);
  // }

  // return <Home />;
}

export default App;
