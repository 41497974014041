import { Container } from "react-bootstrap";
import CustomBreadcrumb from "../../utils/CustomBreadcrumb";

export default function Dashboard(props) {
    return (<>
        <CustomBreadcrumb dashboard={"Dashboard"}>
        </CustomBreadcrumb>
        <Container bsPrefix="main-body">
            <div>Dashboard Page</div>
        </Container>
    </>)
}

