import { Route, Routes } from "react-router-dom";
import Sample from "../Sample";
import Dashboard from "./Dashboard";
import RoleMaster from "../Admin/RoleMaster";
import { Container } from "react-bootstrap";
import UserMaster from "../Master/UserMaster";
 import ProductImageMaster from "../Master/ProductImageMaster";
import EmployeeMaster from "../Admin/EmployeeMaster";
import UserFeedbackMaster from "../Master/UserFeedbackMaster";
import ProjectMaster from "../Master/ProjectMaster";

export default function Main(props) {
  return (
    <Container bsPrefix="main">
      <Routes>
        <Route exact path="/" element={<Dashboard />}></Route>
        <Route exact path="/sample" element={<Sample />}></Route>
        <Route exact path="/usermaster" element={<UserMaster />}></Route>
        <Route exact path="/employeemaster" element={<EmployeeMaster />}></Route>
        <Route exact path="/userfeedbackmaster" element={<UserFeedbackMaster />}></Route>
        <Route exact path="/projectmaster" element={<ProjectMaster />}></Route>
       
        <Route exact path="/role" element={<RoleMaster />}></Route>
        <Route exact path="/productimage" element={<ProductImageMaster />}></Route>
      </Routes>
    </Container>
  );
}
