import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import AddEditProjectMaster from "./AddEditProjectMaster";
import ResponsiveTable from "../../utils/ResponsiveTable";
import editIcon from "../../assets/editA.svg";
import deleteIcon from "../../assets/deleteA.svg";
import viewIcon from "../../assets/viewA.svg";
import historyIcon from "../../assets/history.svg";
import CustomBreadcrumb from "../../utils/CustomBreadcrumb";
import Alert from "../common/Alert";
import { getProjects, deleteProject, getHistoryProjects } from "../../services/project-service";
import constants from "../../utils/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import 'react-datepicker/dist/react-datepicker.css';
import ExcelUploadProjectMaster from "./ExcelUploadProjectMaster";
import { formatDateForDisplay } from "../../utils/common-util"
import { getUser } from "../../services/master-service";



const ProjectMaster = () => {
  const [action, setAction] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [uploadForm, setUploadForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState({});
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [dataHistory, setDataHistory] = useState([]);
  const [finalHistory, setFinalHistory] = useState([]);
  const [msg, setMsg] = useState("");
  const [userData, setUserData] = useState("");
  const [applicableInData, setApplicableInData] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  const [headers, setHeaders] = useState([
    {
      displayName: "Type Of Orders",
      columnName: "order_type",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Dealer Name",
      columnName: "dealer_name",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Dealer Code",
      columnName: "dealer_code",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "End Customer Name",
      columnName: "end_customer_name",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Order Booked Date",
      columnName: "order_date",
      dataType: "date",
      width: 15,
      displayIndication: true,
      formatter:formatDateForDisplay
    },
    {
      displayName: "Project Number",
      columnName: "project_no",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Product Group",
      columnName: "product_group",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Order Value In LACS",
      columnName: "order_value",
      dataType: "number",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Branch",
      columnName: "branch",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Planned Dispatch Date",
      columnName: "planned_dispatch_date",
      dataType: "date",
      width: 15,
      displayIndication: true,
      formatter:formatDateForDisplay
    },
    {
      displayName: "Actual Planned Dispatch Date",
      columnName: "actual_dispatch_date",
      dataType: "date",
      width: 15,
      displayIndication: true,
      formatter:formatDateForDisplay
    },
    {
      displayName: "Partial / Full / Final",
      columnName: "dispatch_type",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Order Status",
      columnName: "completed_status",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "PO Number",
      columnName: "po_number",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Vehicle / Consignment Number",
      columnName: "vehicle_consignment_number",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Type of Consignment",
      columnName: "consignment_type",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Driver Number",
      columnName: "driver_number",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Notification Status",
      columnName: "notification_status",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Notification Type",
      columnName: "notification_type",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Generic Message",
      columnName: "variable_1",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Control Variable",
      columnName: "variable_2",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
    {
      displayName: "Revision Count",
      columnName: "revision_count",
      dataType: "text",
      width: 15,
      displayIndication: true,
    },
  ]);

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  const handleUpload = () => {
    setShowForm(false)
    setUploadForm(true);
  };

  useEffect(() => {
    setLoading(true);
    const data = getProjects();

    Promise.all([data])
      .then((responses) => {
        console.log(responses);
        setLoading(false);
        setData(responses[0]);
      })
      .catch((error) => {
        setLoading(false);
        Alert("error", error);
      });
  }, [reload]);

  useEffect(() => {
    const data = getUser();

    Promise.all([data])
      .then((responses) => {
        console.log(responses);
        setUserData(responses[0])
      })
      .catch((error) => {
        setUserData(null);
      });
  }, [reload]);

  useEffect(() => {
    const data = getHistoryProjects();

    Promise.all([data])
      .then((responses) => {
        console.log(responses);
        setDataHistory(responses[0])
      })
      .catch((error) => {
        setDataHistory(null);
      });
  }, [reload]);

  function setStatus(item) {
    let result = getProjects(item);
    result
      .then((resp) => {
        Alert("success", "Status Updated Successfully");
        getProjects();
      })
      .catch((error) => {
        Alert("error", error);
      });
  }
  let icons = [
    {
      src: editIcon,
      srcd: deleteIcon,
      srcv: viewIcon,
      srcvh: historyIcon,
      click: edit,
      clickv: view,
      clickvh: viewHistory,
      clicks: delet,
    },
  ];
  function edit(item) {
    setAction("Edit");
    setRowData(item);
    setShowForm(true);
  }
  function view(item) {
    setAction("View");
    setRowData(item);
    setShowForm(true);
  }
  function viewHistory(item) {
    setAction("View History");
    setRowData(item);
    const history = dataHistory.filter(el => el.SK.includes("ProjectNo#"+ item.project_no + "#HistoryNo#"));
    if (history.length !== 0) {
    setFinalHistory(history)
    setShowForm(true);
    } else if (history.length === 0) {
      const noRecords = [{"project_no":"No Records"}]
      setFinalHistory(noRecords)
      setShowForm(true);
    }
  }
  async function delet(item) {
    const MySwal = withReactContent(Swal);
    let title = "Delete Project";
    let confirmationMessage = "Are you sure, you want to Delete this Project?";
    MySwal.fire({
      title: title,
      html: confirmationMessage,
      ...constants.CONFIRMATION_BOX_CONFIG,
    }).then(async (selection) => {
      if (selection.isConfirmed) {
        setLoading(true);
        let result;
       
        result = await deleteProject(item);
        if (result) {
          Alert("success", result.Message);
        }
        else {
          setErrorMessage(" record not deleted")
        }
      setLoading(false);
      setReload(!reload);
      }
    });
  }




  

  return (
    <>
      {loading && (
        <div className="spinner-box">
          {" "}
          <Spinner animation="border" className="spinner"></Spinner>
        </div>
      )}
      {showForm && (
        <AddEditProjectMaster
          show={showForm}
          action={action}
          setMsg={setMsg}
          rowdata={rowData}
          setReload={() => setReload(!reload)}
          projectData={data}
          applicableInData={applicableInData}
          onHide={() => setShowForm(false)}
          handleUpload={handleUpload}
          userData={userData}
          finalHistory={finalHistory}
        />
      )}
      {uploadForm && (
        <ExcelUploadProjectMaster
          data={data}
          show={uploadForm}
          setReload={() => setReload(!reload)}
          handleUpload={handleUpload}
          onHideUpload={() => setUploadForm(false)}
        />
      )}
      <CustomBreadcrumb parentMenu={"Master"} menu={"Maintain Projects"}>
        {" "}
      </CustomBreadcrumb>
      <Container bsPrefix="main-body">
        <div className="page-title">Maintain Project Master</div>
        <div className="page-sub-title">Add or Edit Project Master</div>
        <ResponsiveTable
          headers={headers}
          data={data}
          icons={icons}
          dataType={"Maintain Projects"}
          button={"New Project"}
          handleAdd={handleAdd}
          sessionName={"Project Master"}
          showDownload={true}
          excelDownload={true}
          downloadFileName={"projectMaster.xlsx"}
        ></ResponsiveTable>
      </Container>
    </>
  );
};

export default ProjectMaster;



