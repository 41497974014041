import { Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ChevronDown, ChevronUp, Square } from "react-bootstrap-icons";
import { Link, useLocation } from "react-router-dom";
import "./navItem.css"

export default function NavItem(props) {
    let location = useLocation();
    return (<>
        {props.menuType=="parent" && <Nav.Item as="li" onClick={() => {
                props.setOpen((prevState) => {
                    return {
                        ...prevState,
                        [props.menu]: props.open[props.menu]?false:true
                    }
                }
                )
            }}>
            <Nav.Link  as={Link} to={props.path} eventKey={props.menu} bsPrefix={`nav-list-item ${props.menuType}  ${location.pathname === props.path ? "active" : "inactive"}`} >
            {props.menuType==="parent"  && <OverlayTrigger placement="right" overlay={<Tooltip>{props.menu}</Tooltip>}>
            <img src={props.icon}></img>
                    {/* <span><Square></Square></span> */}
                </OverlayTrigger>}
                <span className="menu-name">{props.menu}</span>
            </Nav.Link>

           {props.ariaControls && <> {!props.open[props.menu] && <ChevronDown  className="menu-arrow" onClick={() => {
                props.setOpen((prevState) => {
                    return {
                        ...prevState,
                        [props.menu]: true
                    }
                }
                )
            }} aria-controls={props.ariaControls}></ChevronDown>}
           {props.open[props.menu] && <ChevronUp  className="menu-arrow" onClick={() => {
                props.setOpen((prevState) => {
                    return {
                        ...prevState,
                        [props.menu]: false
                    }
                }
                )
            }} aria-controls={props.ariaControls}></ChevronUp>}

            </>}
        </Nav.Item> }
        {props.menuType=="menu" && <Nav.Item as="li" onClick={() => {
                // props.setSidebar("closed")
            }}
                >
            <Nav.Link as={Link} to={props.path} eventKey={props.menu} bsPrefix={`nav-list-item ${props.menuType}  ${location.pathname === props.path ? "active" : "inactive"}`} >
                <span className="menu-name">{props.menu}</span>
            </Nav.Link>
        </Nav.Item> }
        </>
    )

}