import { Container, Spinner } from "react-bootstrap";
import ResponsiveTable from "../utils/ResponsiveTable";
import CustomBreadcrumb from "../utils/CustomBreadcrumb";
import editIcon from "../assets/edit.svg"
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Sample(props) {

    const [loading, setLoading] = useState(true);
    const MySwal = withReactContent(Swal);

    let headers = [
        { displayName: "Status", columnName: "Status", dataType: "text", setStatus: setStatus ,width:10,displayIndication: true },
        { displayName: "Employee Name", columnName: "EmployeeName", dataType: "text", width:10,displayIndication: true },
        { displayName: "Salary Code", columnName: "SalaryCode", dataType: "number",width:10 ,displayIndication: true },
        { displayName: "Phone Number", columnName: "PhoneNumber", dataType: "text",width:10,displayIndication: true  },
        { displayName: "DOB", columnName: "DOB", dataType: "date",displayIndication: true  }
    ]
    let icons = [{ src: editIcon, click: edit }]

    function edit(item) {       
        console.log("item", item)

    }

    function handleAdd() {
        MySwal.fire({
            title: "Add New Role & Authorization",
            html: " Are you sure do you want to Add New Role & Authorization?",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            reverseButtons: true,
            showCloseButton:true,
            customClass: "swal-confirmation",
        }).then(async (selection) => {
            if (selection.isConfirmed) {
                
            }
        })
    }

    function setStatus(item) {

        console.log("item", item)
        console.log("data");
        console.log(data);
        let newData = data.map(el => (
            el.EmployeeName === item.EmployeeName ? item : el
        ))
        setData(newData);
    }

    const [data, setData] = useState()

    useEffect(() => {
        let result = [
            { Status: "Active", EmployeeName: "Leena", SalaryCode: 123, PhoneNumber: "489493", DOB: "01/02/1995" },
            { Status: "Active", EmployeeName: "Yamini", SalaryCode: 124, PhoneNumber: "48934938493", DOB: "1995-02-01" },
            { Status: "Active", EmployeeName: "Rahul", SalaryCode: 333, PhoneNumber: "832332323993", DOB: "1995-02-01" },
            { Status: "Inactive", EmployeeName: "ArunKumar", SalaryCode: 123, PhoneNumber: "3323", DOB: "1995-02-01" },
            { Status: "Active", EmployeeName: "Shithijay", SalaryCode: 767, PhoneNumber: "48934938493", DOB: "1995-02-01" },
            { Status: "Active", EmployeeName: "Sagar", SalaryCode: 323, PhoneNumber: "48934938493", DOB: "1996-02-01" },
            { Status: "Active", EmployeeName: "Girish", SalaryCode: 123, PhoneNumber: "48934938493", DOB: "1996-02-01" },
            { Status: "Active", EmployeeName: "ryeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeu", SalaryCode: 124, PhoneNumber: "48934938493", DOB: "1995-02-01" },
            { Status: "Active", EmployeeName: "Vishal", SalaryCode: 124, PhoneNumber: "48934938493", DOB: "1995-02-01" },
            { Status: "Active", EmployeeName: "Indra", SalaryCode: 1233, PhoneNumber: "48934938493", DOB: "1995-02-01" },
            { Status: "Active", EmployeeName: "Anupama", SalaryCode: 124, PhoneNumber: "48934938493", DOB: "1995-02-01" },
            { Status: "Active", EmployeeName: "Homiar", SalaryCode: 11, PhoneNumber: "48934938493", DOB: "1995-02-01" },
            { Status: "Active", EmployeeName: "Shridevi", SalaryCode: 1243, PhoneNumber: "48934938493", DOB: "1995-02-01" },
            { Status: "Active", EmployeeName: "", SalaryCode: 1243, PhoneNumber: "48934938493", DOB: "1995-02-01" }
        ]
        setData(result);
        setLoading(false);
    }, [])
    return (<>
        {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner">
        </Spinner></div>}
        <CustomBreadcrumb parentMenu={"Master"} menu={"Sample"}>
        </CustomBreadcrumb>
        <Container bsPrefix="main-body">
            <div className="page-title">Sample Table</div>
            <div className="page-sub-title">Add and Edit</div>
            <ResponsiveTable headers={headers} data={data} icons={icons} dataType={"users"} button={"New Sample"} handleAdd={handleAdd} sessionName={"Maintain"}></ResponsiveTable>
        </Container>
            {/* <h1>hiio</h1> */}
    </>)
}