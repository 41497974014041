import { useRef, useState } from "react";
import { FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { compare } from "./common-filter";
import constants from "./constants";
import GlobalSearchIcon from "../assets/GlobalSearchIcon.svg"
import crossSearch from "../assets/x.svg"
import "./globalSearch.css"

export default function GlobalSearch(props) {

    const searchInput = useRef("");
    const [showSearchBar, setShowSearchBar] = useState()

    function search(searchText) {
        let tempObj = [];
        for (let obj of props.data) {
            for (let header of props.headers) {
                if (obj[header.columnName] && obj[header.columnName] != "") {
                    if (compare(constants.CONTAINS, obj[header.columnName], searchText)) {
                        tempObj.push(obj);
                        break;
                    }
                }
            }
        }

        props.setData(tempObj);
    }

    function toggleShow() {
        setShowSearchBar(!showSearchBar);
        props.setShowDownButn(!props.showDownButn);
        console.log("props.showDownButn",props.showDownButn)
    }

    return (
        <>
            {/* <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Search</Tooltip>}
            > */}
                <img
                    className={`search-global-icon ${showSearchBar == true ? "active" : ""} `}
                    src={GlobalSearchIcon}
                    alt="search"
                    onClick={toggleShow}
                />
            {/* </OverlayTrigger> */}
            {
                showSearchBar && <>
                    <FormControl ref={searchInput}
                        className="search-bar"
                        type="text"
                        onChange={() => search(searchInput.current.value)}
                        aria-label="Search"
                        aria-describedby="search"
                        placeholder="Search here"
                    />
                    <span className="clear-global-search" onClick={() => search("")}>
                        <img
                            className="cross-button"
                            src={crossSearch}
                            onClick={toggleShow}
                        />

                    </span>
                </>
            }
        </>
    )
}