
import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import AddEditProductImageMaster from "./AddEditProductImageMaster";
import constants from "../../utils/constants";
import ResponsiveTable from "../../utils/ResponsiveTable";
import {  getAllProductImage , deleteProductImage} from "../../services/productimage-service";
import editIcon from "../../assets/editA.svg";
import deleteIcon from "../../assets/deleteA.svg";
import viewIcon from "../../assets/viewA.svg";
import CustomBreadcrumb from "../../utils/CustomBreadcrumb";
import { formatDateForDisplay } from "../../utils/common-util"
import Alert from "../common/Alert";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ProductImageMaster = () => {
  const [action, setAction] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rowData, setRowData] = useState({});
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState();
  
  const viewlink = async (item) => {    
    setRowData(item);
  }
  const [headers, setHeaders] = useState(
    [
        { displayName: "Product Name", columnName: "product_name", dataType: "text", width: 10, displayIndication: true },
      { displayName: "Product Image", columnName: "product_image", dataType: "link", width: 10, displayIndication: true, method: viewlink },
      { displayName: "Created By", columnName: "CreatedBy", dataType: "text", width: 10, displayIndication: true },
      { displayName: "Created On", columnName: "CreatedOn", dataType: "date", width: 10, displayIndication: true, formatter:formatDateForDisplay },
      { displayName: "Modified By", columnName: "ModifiedBy", dataType: "text", width: 10, displayIndication: false },
      { displayName: "Modified On", columnName: "ModifiedOn", dataType: "date", width: 10, displayIndication: false, formatter:formatDateForDisplay },
    ]
  );


  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };


function getDataDetails(){
  setLoading(true)
    const data = getAllProductImage();   
    Promise.all([data]).then((responses) => {
      setLoading(false);      
      setData(responses[0]);
    }).catch((error) => {
      setLoading(false);
      Alert("error", error);
    })

}

  useEffect(() => {
    getDataDetails();
  }, [reload]);

  let icons = [{ src: editIcon, 
    srcd: deleteIcon,
    srcv: viewIcon,
    click: edit,
    clickv: view,
    clicks: deleteProdImage
   }]
  function edit(item) {
    setAction("Edit");
    setRowData(item);
    setShowForm(true);
  }
  function view(item) {
    setAction("View");
    setRowData(item);
    setShowForm(true);
  }
  function deleteProdImage(item) {
    const MySwal = withReactContent(Swal);
    let title = "Delete Product Image";
    let confirmationMessage = "Are you sure, you want to Delete this Product Image?";
    MySwal.fire({
      title: title,
      html: confirmationMessage,
      ...constants.CONFIRMATION_BOX_CONFIG,
    }).then(async (selection) => {
      if (selection.isConfirmed) {
        setLoading(true);
        let result;
        let prodname = {
          product_name: item.product_name,
        };
        result = deleteProductImage(prodname);
         result
        .then((resp) => {
          Alert("success", resp.Message);
         
        })
        .catch((err) => {
          setErrorMessage(err);
        });
      setLoading(false);
      setReload(!reload);
      }
    });
  }
  return (<>
    {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner">
    </Spinner></div>}
    {showForm && (
      <AddEditProductImageMaster
        show={showForm}
        action={action}
        rowdata={rowData}
        setReload={() => setReload(!reload)}
        productImageData={data}
        onHide={() => setShowForm(false)}
      />
    )}
    
    <CustomBreadcrumb parentMenu={"Master"} menu={"Maintain Product Image"}> </CustomBreadcrumb>
    <Container bsPrefix="main-body">
      <div className="page-title">Maintain Product Image</div>
      <div className="page-sub-title">Add or Edit Product Image</div>
      <ResponsiveTable headers={headers} data={data} icons={icons} dataType={"Product Image Master"} DisplayColumnChooser={false} button={"New Product Image"} handleAdd={handleAdd} sessionName={"Product Image Master"} ></ResponsiveTable>
    </Container>
  </>)
}
export default ProductImageMaster;